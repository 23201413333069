export const colors = {
	brand: {
		DEFAULT: '#027f88',
		100: '#e6f2f3',
		200: '#9acccf',
		300: '#67b2b8',
		400: '#3599a0',
		500: '#027f88',
		600: '#02666d',
		700: '#014c52',
		800: '#013336',
		900: '#00191b',
	},
	neutral: {
		DEFAULT: '#dddddd',
		50: '#fcfcfc',
		100: '#f7f7f7',
		200: '#f2f2f2',
		300: '#ebebeb',
		400: '#e3e3e3',
		500: '#dddddd',
		600: '#b0b0b0',
		700: '#767676',
		800: '#595959',
		900: '#222222',
		950: '#171717',
	},
	green: {
		100: '#d8e7cc',
		200: '#b1cf9a',
		300: '#8ab867',
		400: '#63a035',
		500: '#3c8802',
		600: '#306d02',
		700: '#245201',
		800: '#183601',
		900: '#0c1b00',
	},
	red: {
		100: '#e7cecc',
		200: '#cf9d9a',
		300: '#b86d67',
		400: '#b3635d',
		500: '#a03c35',
		600: '#6d0902',
		700: '#520701',
		800: '#360401',
		900: '#1b0200',
	},
	black: '#000',
	white: '#fff',
	current: 'currentColor',
	'current/10': 'color-mix(in srgb, currentColor 10%, transparent)',
	'current/20': 'color-mix(in srgb, currentColor 20%, transparent)',
	'current/30': 'color-mix(in srgb, currentColor 30%, transparent)',
	'current/40': 'color-mix(in srgb, currentColor 40%, transparent)',
	'current/50': 'color-mix(in srgb, currentColor 50%, transparent)',
	'current/60': 'color-mix(in srgb, currentColor 60%, transparent)',
	'current/70': 'color-mix(in srgb, currentColor 70%, transparent)',
	'current/80': 'color-mix(in srgb, currentColor 80%, transparent)',
	'current/90': 'color-mix(in srgb, currentColor 90%, transparent)',
	transparent: 'transparent',
};
