import { loadStripe } from '@stripe/stripe-js';
import { createContext, createEffect, createMemo, createResource, createSignal, useContext } from 'solid-js';
import { colors } from '@troon/tailwind-preset/colors';
import { captureException } from '@sentry/solidstart';
import { getConfigValue } from '../modules/config';
import type { Currency } from '../graphql';
import type { Stripe, StripeElements, StripeElementsOptionsMode } from '@stripe/stripe-js';
import type { Accessor, ParentProps, Resource } from 'solid-js';

const StripeContext = createContext<{
	stripe: Resource<Stripe | null>;
	elements: Accessor<StripeElements | undefined>;
	setAmount: (amount: { value: number; cents: number; code: string }) => void;
}>({ elements: () => undefined, setAmount: () => {}, stripe: (() => null) as Resource<null> });

export function StripeProvider(
	props: ParentProps<{
		mode?: StripeElementsOptionsMode['mode'];
		paymentMethodCreation?: StripeElementsOptionsMode['paymentMethodCreation'];
	}>,
) {
	const [stripeResource] = createResource(async () => {
		const loadWithTimeout = (attempt: number) =>
			Promise.race(
				[
					loadStripe(getConfigValue('STRIPE_PUBLISHABLE_KEY')),
					attempt < 2
						? new Promise((_, reject) => setTimeout(() => reject(new Error('Stripe load timeout')), 5_000))
						: false,
				].filter(Boolean),
			);

		async function tryLoad(attempt: number) {
			const maybeStripe = await loadWithTimeout(attempt);
			if (maybeStripe instanceof Error || !maybeStripe) {
				throw maybeStripe;
			}
			return maybeStripe as Stripe;
		}

		try {
			return await tryLoad(0);
		} catch {
			try {
				return await tryLoad(1);
			} catch {
				captureException(new Error('Multiple Stripe load timeouts, attempting final load without timeout'));
				return await loadStripe(getConfigValue('STRIPE_PUBLISHABLE_KEY'));
			}
		}
	});
	const [amount, setAmount] = createSignal<Pick<Currency, 'value' | 'cents' | 'code'> | undefined>();
	const [mounted, setMounted] = createSignal(false);

	createEffect(() => {
		if (stripeResource.state === 'errored') {
			captureException(stripeResource.error);
		}
	});

	createEffect(() => {
		const amt = amount();
		if (mounted() && amt) {
			elements()?.update({ amount: amt.cents ?? undefined });
		}
	});

	const elements = createMemo<StripeElements | undefined>((prev) => {
		if (!stripeResource() || mounted()) {
			return prev;
		}

		// Only require amount for payment mode
		if (props.mode !== 'setup' && !amount()) {
			return prev;
		}

		setMounted(true);

		return stripeResource()!.elements({
			paymentMethodTypes: ['card'],
			mode: props.mode ?? 'subscription',
			amount: amount()?.cents ?? Math.round((amount()?.value ?? 0) * 100),
			currency: amount()?.code.toLowerCase() ?? 'usd',
			// Always show the skeleton loading animation
			loader: 'always',
			paymentMethodCreation: props.paymentMethodCreation ?? undefined,
			fonts: [
				{
					cssSrc: 'https://fonts.bunny.net/css?family=poppins',
				},
			],
			appearance: {
				theme: 'flat',
				variables: {
					fontFamily:
						'Poppins, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
					fontSizeBase: '16px',
					spacingUnit: '4px',
					colorBackground: colors.white,
					focusBoxShadow: '#000000',
					colorPrimary: colors.brand.DEFAULT,
					colorPrimaryText: colors.brand.DEFAULT,
					colorDanger: colors.red['500'],
					colorSuccess: colors.green['500'],
					colorText: colors.neutral['950'],
					gridRowSpacing: '1.25rem',
					gridColumnSpacing: '1rem',
				},
				rules: {
					'.Input': {
						border: `1px solid ${colors.neutral.DEFAULT}`,
						borderRadius: '0.5rem',
					},
					'.Input:focus': {
						boxShadow: `${colors.brand.DEFAULT} 0px 0px 0px 1px`,
					},
					'.Label': {
						fontSize: '1rem',
						fontWeight: '500',
					},
					'.Tab:hover': {
						backgroundColor: colors.brand['200'],
					},
					'.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
						backgroundColor: colors.brand['100'],
						color: colors.brand.DEFAULT,
					},
				},
			},
		});
	});

	return (
		<StripeContext.Provider value={{ elements, setAmount, stripe: stripeResource as Resource<Stripe | null> }}>
			{props.children}
		</StripeContext.Provider>
	);
}

export function useStripe() {
	return useContext(StripeContext);
}
